






















import Vue from 'vue'
import wx from 'weixin-js-sdk'
import { WECHAT_APPID } from '@/utils/constants'
import moment from 'moment'
import request from '@/utils/request'
import sha1 from 'sha1'
import AmrPlayer from '@/components/AmrPlayer.vue'

export default Vue.extend({
    components: {
        AmrPlayer,
    },
    data() {
        return {
            wxConfiged: false, // 微信是否已配置
            isRecording: false, // 是否正在录音
            startTime: 0, // 录音开始时间
            serverId: '', // 录音微信服务ID，即media_id,可用于后台下载音乐媒体使用
            listenLocalId: '', // 正在试听的本地ID
            recordList: [], // 当前录音记录
        }
    },
    created() {
        this.initWeixin()
    },
    
    methods: {
        async initWeixin() {
            this.wxConfiged = true
            const timestamp = moment().valueOf()
            const nonceStr = 'cztobaccoh5'
            const jsapiTicket = await request.get('/api/account/office_account/jsapi_ticket')
            console.log('jsapiTicket', jsapiTicket)
            const params = `jsapi_ticket=${jsapiTicket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${window.location.href}`
            console.log('params', params)
            const signature = sha1(params)
            wx.ready(() => {
                this.wxConfiged = true
                wx.onVoiceRecordEnd({ complete: this.stopRecord })
            })
            wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId: WECHAT_APPID, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature,// 必填，签名
                jsApiList: ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'uploadVoice', 'downloadVoice', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd'] // 必填，需要使用的 JS 接口列表
            })
        },
        startRecord() {
            this.stopAll()
            wx.startRecord({success: () => {
                this.startTime = new Date().getTime()
                this.isRecording = true
            }})
        },
        onTouchstart(event: Event) {
            event.stopPropagation()
            event.preventDefault()
            this.startRecord()
        },
        onTouchmove() {
            console.log('touchmove')
        },
        onTouchend() {
            if (!this.isRecording) return
            if (new Date().getTime() - this.startTime < 300) {
                // console.log('间隔太短')
                this.startTime = 0
                wx.stopRecord()
                this.isRecording = false
            } else {
                wx.stopRecord({ success: this.stopRecord, fail: (res: any) => console.error(JSON.stringify(res))})
            }
        },
        stopRecord(res: any) {
            console.log('stopRecord')
            this.isRecording = false
            this.recordList.push({
                startTime: this.startTime,
                endTime: new Date().getTime(),
                localId: res.localId,
                isListen: false,
            })
            // 上传到微信服务器
            wx.uploadVoice({
                localId: res.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: (serverRes: any) => {
                    var { serverId } = serverRes // 返回音频的服务器端ID
                    request.post('/api/chat/wechat_audio', {serverId}).then((filePath: any) => {
                        this.recordList.push({
                            startTime: this.startTime,
                            endTime: new Date().getTime(),
                            localId: res.localId,
                            serverId,
                            filePath,
                            isListen: false,
                        })
                    }) // 上传到自己服务器
                }
            })
        },
        formatTime(time: number) {
            return moment(time).format('mm:ss')
        },
        play(item: any) {
            this.stopAll()
            item.isListen = true
            wx.onVoicePlayEnd({ // 监听播放结束
                success: () => {
                    item.isListen = false
                    console.log('试听监听结束')
                }
            })
            wx.playVoice({
                localId: item.localId // 需要播放的音频的本地ID，由stopRecord接口获得
            })
        },
        stopAll() {
            this.recordList.forEach((item: any) => {
                if(item.isListen) { // 正在试听
                    wx.stopVoice({localId: item.localId})
                    item.isListen = false
                }
            })
        },
        stop(item: any) {
            wx.stopVoice({localId: item.localId})
            if(item.isListen) { // 正在试听
                item.isListen = false
            }
        }
    }
})
