












import Vue from 'vue'
import BenzAMRRecoder from 'benz-amr-recorder'
export default Vue.extend({
    props: {
        url: {type: String, required: true},
    },
    data() {
        return {
            duration: 0,
            currentPosition: 0,
            state: 0, // 0：未播放可播放 1：正在播放 暂停 2：正在暂停 继续
        }
    },
    created() {
        const amr = new BenzAMRRecoder()
        amr.initWithUrl(this.url).then(() => {
            this.duration = Math.ceil(amr.getDuration())
        })
        amr.onPlay(() => this.state = 1)
        amr.onStop(() => this.state = 0)
        amr.onPause(() => this.state = 2)
        amr.onResume(() => this.state = 1)
        amr.onEnded(() => this.state = 0)
        this.interval = setInterval(() => {
            if (amr) {
                this.currentPosition = amr.getCurrentPosition()
            } else {
                this.currentPosition = 0
            }
        }, 10)
        this.amr = amr
    },
    destroyed() {
        if(this.interval) clearInterval(this.interval)
    },
    methods: {
        playOrPauseOrResume() {
            this.amr.playOrPauseOrResume()
        },
        stop() {
            this.state = 1
            this.amr.stop()
        },
    }
})
